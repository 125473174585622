import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_TAXES, propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';

const LineItemTaxesMaybe = props => {
  const { lineItems, intl, isProvider } = props;

  const taxesLineItem = lineItems.find(
    item => item.code === LINE_ITEM_TAXES
  );
  
  const taxesPrice = taxesLineItem ? formatMoney(intl, taxesLineItem.unitPrice) : null;
  const classes = `${isProvider ? css.grayed : ''}`;

  return (
    <div className={css.lineItem}>
      <span className={`${css.itemLabel} ${classes}`}>
        <FormattedMessage id="OrderBreakdown.taxes" />
      </span>
      {taxesLineItem && <span className={`${css.itemValue} ${classes}`}>{taxesPrice}</span>}
      {!taxesLineItem && <span className={`${css.itemValue} ${classes}`}><FormattedMessage id="OrderBreakdown.taxesCalculatedAtCheckout" /></span>}
    </div>
  );
};

LineItemTaxesMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemTaxesMaybe;
