import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_SHIPPING_FEE, propTypes } from '../../util/types';
import continents from '../../translations/continents.json';
import countries from '../../translations/countries.json';

import css from './OrderBreakdown.module.css';

const LineItemShippingFeeMaybe = props => {
  const { lineItems, intl } = props;

  const shippingFeeLineItem = lineItems.find(
    item => item.code === LINE_ITEM_SHIPPING_FEE && !item.reversal
  );

  let domesticShipping;
  let country;
  let continent;
  if (shippingFeeLineItem && shippingFeeLineItem.metadata) {
    const metadata = shippingFeeLineItem.metadata;
    const code = metadata.buyerCountry;
    country = countries.find(c => c.code === code);
    if (metadata.buyerCountry && metadata.buyerCountry === shippingFeeLineItem.metadata.sellerCountry) {
      domesticShipping = true;
    }
  }
  if (country && country.continent) {
    continent = continents.find(c => c.key === country.continent);
  }
  let destinationLabel = ''
  if (domesticShipping) {
    destinationLabel = ` domestic`;
  } else if (continent) {
    destinationLabel = ` to ${continent.en}`;
  }

  return shippingFeeLineItem ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="OrderBreakdown.shippingFee" />
        {destinationLabel}
      </span>
      <span className={css.itemValue}>{formatMoney(intl, shippingFeeLineItem.lineTotal)}</span>
    </div>
  ) : null;
};

LineItemShippingFeeMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemShippingFeeMaybe;
