// Add here the translations of the country names using key "<language_code>: 'transalation'" e.g. fi: 'Afganistan'
// Native script sourced from: https://github.com/annexare/Countries https://unpkg.com/browse/countries-list@2.6.1/dist/
// prettier-ignore
import countryCodes from './countries.json';

const getCountryCodes = lang => {
  // Add the lnew locale here so that the correct translations will be returned.
  // If locale is unknown or the translation is missing, this will default to english coutnry name.
  const codes = countryCodes.map(c => {
    let countryName = c[lang] ? c[lang] : c['en'];
    if (c.native !== countryName) {
      countryName += ` (${c.native})`;
    }
    const counryCode = c.code;

    return { code: counryCode, name: countryName };
  });
  return codes;
};

export default getCountryCodes;